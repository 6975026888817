<template>
  <div class="page-wrapper-pc">
    <div class="container" style="width:600px">
      <div style="background-color:white; padding: 32px;border-radius: 6px">
        <div style="text-align: center">
          <i class="material-icons" style="color:#292c32;font-size:50px;margin-bottom:12px">check_circle</i>
          <div style="font-size:18px;font-weight:600;margin-bottom:24px">신청이 접수되었습니다.</div>
          <div style="color:#999999;font-size:14px">
            <span style="color:#FF6600;font-weight:600">{{ limitDate }} </span>까지 은행에 입금해 주셔야<br>신청이 완료됩니다.
          </div>
          <div class="flex-justify full-width">
            <div :style="infoStyle" style="text-align:left;line-height:28px" class="size-14">
              <div class="flex" style="align-items: flex-start;">
                <div class="col-4">입금 은행</div>
                <div class="col-8">
                  <div>NH농협은행</div>
                  <div>301-0234-5038-61</div>
                </div>
              </div>
              <div class="flex-align">
                <div class="col-4">예금주</div>
                <div class="col-8">런치팩 주식회사</div>
              </div>
              <div class="flex-align">
                <div class="col-4">입금 금액</div>
                <div class="col-8">{{ info.price | currency }}</div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div style="margin: 32px auto 0;width:192px">
        <div class="flex-center unselect btn-list"
             @click="$router.push('/mypage/my_service')">신청 내역 확인
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'ServiceRequestDone2',
    data() {
      return {
        info: undefined
      }
    },
    created() {
      this.info = this.$store.getters.accountDone;
      if(!this.info) {
        this.toast('올바른 접근이 아닙니다');
        this.$router.back();
      }
      this.$store.commit('setAccountDone', undefined);
    },
    computed: {
      infoStyle() {
        return {
          padding: '30px 12px',
          borderTop: '1px solid #ddd',
          width: '360px',
          margin: '50px auto 0 auto'
        }
      },
      imgStyle() {
        return {
          width: '50px',
          height: '50px',
          objectFit: 'cover'
        }
      },
      limitDate() {
        return this.dayjs().add(this.dayjs.duration({'days': 1})).format('YYYY.MM.DD');
      }
    },
    watch: {}
  }
</script>
<style lang="stylus">
  .btn-list
    border-radius 4px
    background-color #FF6600
    color white
    text-align center
    font-size 14px
    height 48px
    padding 0 20px
</style>

